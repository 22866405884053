import React from 'react';
import { get } from 'lodash';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const PLAYGROUND_ACCOUNT_STATUS_QUERY = gql`
  query CloudPlayground_myAccountStatus {
    CloudPlayground_myAccountStatus {
      id
      tcAccepted
    }
  }
`;

const MyAccountStatusQuery = ({ children }) => {
  const { data } = useQuery(PLAYGROUND_ACCOUNT_STATUS_QUERY);
  const tcAccepted = get(data, 'CloudPlayground_myAccountStatus.tcAccepted');

  return React.cloneElement(children, { visible: tcAccepted === false });
};

export { MyAccountStatusQuery };
