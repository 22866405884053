import '../src/styles/fonts.css';
import './index.less';

import App from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import { ConfigProvider } from '@a-cloud-guru/rainbow-ui';
import * as GoogleTagManager from 'lib/analytics/gtag';
import * as Segment from 'lib/segment';
import { TeamsPage } from 'page-components/TeamsPage';
import { TeamsSkeletonLayout } from 'layouts/teams/TeamsSkeletonLayout';
import { GlobalStyle } from 'styles/global';
import { PlaygroundTermsCondition } from 'page-modules/playground-terms-condition';
import { teamsTheme } from 'theme/teamsTheme';
import { ShowBillingNotifications } from 'page-modules/billing-notifications/ShowBillingNotifications';

Router.onRouteChangeComplete = (url) => {
  GoogleTagManager.pageView(url);
  Segment.sendCurrentPage();
  window.Appcues && window.Appcues.page();
};

class TeamsApp extends App {
  render () {
    if (typeof window === 'undefined') {
      return <TeamsSkeletonLayout />;
    }
    const { Component, pageProps, router } = this.props;

    return (
      <ConfigProvider theme={teamsTheme}>
        <Head>
          <title>Business | A Cloud Guru</title>
        </Head>
        <TeamsPage>
          <Component {...pageProps} router={router} />
          <PlaygroundTermsCondition />
          <ShowBillingNotifications />
        </TeamsPage>
        <GlobalStyle />
      </ConfigProvider>
    );
  }
}

export default TeamsApp;
