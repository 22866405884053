import { get } from 'lodash';
import { MyAccountStatusQuery } from './MyAccountStatusQuery';
import { TermsConditionModal } from './TermsConditionModal';
import { useIsPackagingFeatureAuthorised, useProfile } from 'hooks';
import { FEATURES } from 'constants/packaging';
import { dateIsBeforeCloudPlayGroundTCsCutOff } from 'utils/dateComparison';

const PlaygroundTermsCondition = () => {
  const { organisation } = useProfile();
  
  const cloudSandboxesFeatureEnabled = useIsPackagingFeatureAuthorised(FEATURES.CLOUD_SANDBOXES);
  const handsOnLabsFeatureEnabled = useIsPackagingFeatureAuthorised(FEATURES.HANDS_ON_LABS);
  const organisationCreatedBeforeTsAndCsCutoff = dateIsBeforeCloudPlayGroundTCsCutOff(get(organisation, 'createdAt'));
  const organisationHasBeenMigrated = get(organisation, 'cohortMigrationDate', null);

  const shouldRenderPlaygroundTCsModal = 
    cloudSandboxesFeatureEnabled &&
    handsOnLabsFeatureEnabled && 
    organisationCreatedBeforeTsAndCsCutoff &&
    !organisationHasBeenMigrated;

  const playgroundTermsAndConditionsModal = (
    <MyAccountStatusQuery>
      <TermsConditionModal />
    </MyAccountStatusQuery>
  );

  return shouldRenderPlaygroundTCsModal ? playgroundTermsAndConditionsModal : null;
};

export { PlaygroundTermsCondition };
