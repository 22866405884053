import { format, distanceInWordsStrict, differenceInDays, isBefore } from 'date-fns';

const formatDate = ({ date, dateFormat = 'D MMMM YYYY' }) => {
  return date ? format(date, dateFormat) : '';
};

const dateDistanceInWordsToNow = ({ date, addSuffix = true, addPrefix = true }) => {
  return (addPrefix ? 'over ' : '') + distanceInWordsStrict(date, new Date()) + (addSuffix ? ' ago' : '');
};

const differenceInDaysOrWords = ({ from, to = new Date(), inDaysLimit = 90 }) => {
  const periodStartDate = new Date(from);
  const days = differenceInDays(to, periodStartDate);

  return days < inDaysLimit
    ? `${days} days ago`
    : dateDistanceInWordsToNow({
      date: periodStartDate,
      addPrefix: false
    });
};

const dateIsBefore = ({ dateToCompare, date }) => isBefore(dateToCompare, date);

export { formatDate, dateDistanceInWordsToNow, dateIsBefore, differenceInDaysOrWords };
