import { get } from 'lodash';
import { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { usePrevious } from 'hooks/use-previous';
import { useAuth } from 'hooks/use-auth';
import { PROFILE_QUERY } from './ProfileQuery';
import { assignUserRoles } from 'lib/auth/page-authorization/assignUserRoles';

const requireLicenseEnforcement = (organisation) => {
  return get(organisation, 'chargeModel') === 'selfservice';
};

const getRemainingLicenses = (organisation) => {
  if (!organisation) {
    return 0;
  }
  const userLicenseCount = get(organisation, 'subscription.userLicenseCount') || 0;
  const consumedLicenses = get(organisation, 'consumedLicenses') || 0;

  return userLicenseCount - consumedLicenses;
};

const useLoggedInUserProfile = ({ client }) => {
  const { authorized } = useAuth();
  const [refetch, setRefetch] = useState(0);
  const prevRefetch = usePrevious(refetch);
  const [execute, { data, loading }] = useLazyQuery(PROFILE_QUERY, {
    fetchPolicy: prevRefetch !== refetch ? 'network-only' : undefined
  });

  const organisation = get(data, 'organisation') || {};
  const userIdentity = get(data, 'userIdentity') || {};
  const accountSetupOptions = get(data, 'organisationOnboardingAccountSetupActions') || [];

  // eslint-disable-next-line no-unused-vars
  const { roles, ...identityProfileInfo } = userIdentity;

  const packagingFeatures = get(data, 'Packaging_userFeatureAccess.userFeatureAccess') || {};

  const updateOrganisation = (updates = {}) => {
    // We couldn't use updateQuery from the useLazyQuery properties because apparently
    // this hook will get unloaded a few time. This accomplishes the same thing though 🤷‍♂️
    client.writeQuery({
      query: PROFILE_QUERY,
      data: {
        ...(data || {}),
        organisation: {
          ...(get(data, 'organisation') || {}),
          ...updates,
        },
      },
    });
  };

  useEffect(() => {
    if (authorized) {
      execute();
    }
  }, [authorized, refetch]);

  return {
    id: get(userIdentity, 'id'),
    organisation: {
      ...organisation,
      remainingLicenses: getRemainingLicenses(organisation),
      enforceSeatLimit: requireLicenseEnforcement(organisation)
    },
    profile: {
      ...identityProfileInfo,
      organisationRoles: assignUserRoles(userIdentity),
      rawRoles: roles
    },
    loading: refetch === 0 ? loading : false,
    updateOrganisation,
    refetch: () => setRefetch(refetch + 1),
    packagingFeatures,
    accountSetupOptions
  };
};

export { useLoggedInUserProfile };
