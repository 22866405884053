import { IdentifyContext } from './IdentifyContext';
import { useIdentifyCurrentUser } from './use-identify-current-user';
import { useTrackLoggedInAdmin } from './use-track-logged-in-admin';
import { useDriftChat } from './use-drift-chat';
import { useSyncPendo } from './use-sync-pendo';
import { useProfile } from 'hooks/use-profile';

const IdentifyProvider = ({ children }) => {
  const { profile, organisation } = useProfile();

  useIdentifyCurrentUser({ profile, organisation });
  useTrackLoggedInAdmin({ profile, organisation });
  useSyncPendo({ profile });
  useDriftChat({ profile, organisation });

  return (
    <IdentifyContext.Provider>
      {children}
    </IdentifyContext.Provider>
  );
};

export { IdentifyProvider };
