import get from 'lodash.get';
import { Styled } from 'theme-ui';
import { useState } from 'react';
import styled from 'styled-components';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { Button, Modal, Box, Flexbox, Checkbox, colours } from '@a-cloud-guru/rainbow-ui';
import { useProfile } from 'hooks/use-profile';

const ACCEPT_TC_MUTATION = gql`
  mutation CloudPlayground_acceptOrganisationTc {
    CloudPlayground_acceptOrganisationTc {
      accountStatus {
        id
        tcAccepted
      }
    }
  }
`;

const TermsConditionModal = ({ visible }) => {
  const { profile } = useProfile();
  const [canceled, setCanceled] = useState(false);
  const [checked, setChecked] = useState(false);
  const [acceptTc, { loading }] = useMutation(ACCEPT_TC_MUTATION);

  const isAdmin = (get(profile, 'organisationRoles') || []).includes('ORGANISATION_ADMIN');

  return (
    <StyledModal
      width={622}
      sx={{
        '.ant-modal-close-x': {
          mt: 's2'
        },
        '.ant-modal-body': {
          p: 0,
          fontSize: 'rg'
        }
      }}
      centered
      maskClosable={false}
      confirmLoading={loading}
      title={(
        <Styled.h1
          sx={{
            color: colours.navy900,
            fontSize: '2xl',
            my: 's2'
          }}
        >
          Introducing: Hands-on Features
        </Styled.h1>
      )}
      visible={visible && !canceled && isAdmin}
      okText="Accept Terms"
      okButtonProps={{
        disabled: !checked
      }}
      onOk={acceptTc}
      onCancel={() => setCanceled(true)}
      footer={null}
    >
      <Flexbox
        p="16px 24px"
        flexDirection="column"
      >
        <Text color={colours.darkGrey800}>
          A Cloud Guru is proud to introduce an expansive new set of features that will allow students to safely and securely practice their skills in real cloud and server based environments.
        </Text>

        <Text mt={12}>What's included:</Text>

        <FeatureList>
          <li>
            <Flexbox alignItems="flex-start">
              <Flexbox>
                <Text>
                  <b>Cloud Sandboxes:</b> Full access to real AWS, Google Cloud, and Azure environments
                </Text>
              </Flexbox>
            </Flexbox>
          </li>
          <li>
            <Flexbox alignItems="flex-start">
              <Flexbox>
                <Text>
                  <b>Cloud Servers:</b> Launch and run your own dedicated Linux or Windows (B2B only) based servers
                </Text>
              </Flexbox>
            </Flexbox>
          </li>

          <li>
            <Flexbox alignItems="flex-start">
              <Flexbox>
                <Text>
                  <b>Instant Terminal:</b> Access Cloud Servers or Cloud Provider instances through a web-based terminal (and avoid any port blocking on your network, like Port 22)
                </Text>
              </Flexbox>
            </Flexbox>
          </li>

          <li>
            <Flexbox alignItems="flex-start">
              <Flexbox>
                <Text>
                  <b>Hands-on Labs:</b> Full access to pre-configured AWS, Google Cloud, and Azure environments, that allow you to work alongside a course.
                </Text>
              </Flexbox>
            </Flexbox>
          </li>

        </FeatureList>

        <Text mt={16}>
          With new features comes new terms, so you will need to accept these before your organization can start experimenting.
        </Text>

        <Text mt={16}>
          If you would like to know more, please reach out to your customer success manager or <MailLink href="mailto:support@acloud.guru">support@acloud.guru</MailLink>.
        </Text>
        <Flexbox mt={16}>
          <TcChekcbox
            checked={checked}
            onChange={e => setChecked(e.target.checked)}
          />
          <Box ml="s2">I have read and agree to the Hands-on Features <TcLink rel="noopener noreferrer" target="_blank" href="https://acloud.guru/terms/tou-cloud-playground">terms of use</TcLink> on behalf of my organization</Box>
        </Flexbox>

        <Flexbox justifyContent="flex-start" mt={20}>
          <Box>
            <Button
              loading={loading}
              type="positive"
              disabled={!checked}
              onClick={acceptTc}
            >
              Accept Terms
            </Button>
          </Box>

          <Box ml="s3">
            <Button
              onClick={() => setCanceled(true)}
            >
              Cancel
            </Button>
          </Box>

        </Flexbox>
      </Flexbox>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-footer {
    border-top: none;
  }

  .ant-btn-primary:not([disabled]) {
    background-color: ${colours.green};
    border-color: ${colours.green800};

    :hover {
       background-color: ${colours.green800};
    }
  }
`;

const TcLink = styled.a`
  color: ${colours.darkGrey900};
  font-weight: 600;
  text-decoration: underline;

  :hover {
    color: ${colours.darkGrey900};
    text-decoration: underline;
  }
`;

const Text = styled(Box)`
  color: ${colours.darkGrey800};
  line-height: 24px;
`;

const MailLink = styled.a`
  color: ${colours.darkGrey800};
  font-weight: 600;
  text-decoration: underline;

  :hover {
    color: ${colours.darkGrey800};
    text-decoration: underline;
  }
`;

const FeatureList = styled.ul`
  padding-inline-start: 20px;
  margin-block-end: 0;
  color: ${colours.darkGrey600};

  li {
    margin-top: 8px;
  }
`;

const TcChekcbox = styled(Checkbox)`
  font-size: 16px;
  .ant-checkbox-inner {
    border-color: ${colours.lightGrey900};
  }
`;

export { TermsConditionModal };
