import { CLOUD_PLAYGROUND_TCS_CUTOFF_DATE } from 'constants/packaging';
import { dateIsBefore } from './date';

const dateIsBeforeCloudPlayGroundTCsCutOff = (dateToCompare) => dateIsBefore({
  dateToCompare,
  date: CLOUD_PLAYGROUND_TCS_CUTOFF_DATE
});

export { dateIsBeforeCloudPlayGroundTCsCutOff };

