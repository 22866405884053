const IDENTITY_FEATURE_SPLITS = [
  'PLANNED_MAINTENANCE',
  'ONBOARDING_WORKFLOW_STAGE_2',
  'TEAMS_DEMO_ACCOUNT',
  'TEAMS_SUBSCRIPTION_ENFORCEMENT_V2',
  'SKILLS_ASSESSMENT_REQUEST_AZURE',
  'CLOUD_PLAYGROUND_TC',
  'FEATURE_BUSINESS_EXTRA-API-KEYS_ENABLED',
  'DEV_BUSINESS_PRICING-PACKAGING_ENABLED',
  'BILLING_NOTIFICATIONS',
  'FEATURE_ENGAGEMENT-WIDGETS_DISABLED',
  'FEATURE_BULK_DEACTIVATION_ENABLED',
  'B2B_REPORTING',
  'CLP_BANNER',
  'CLP_GUIDE',
  'BMT_CLOUD_PLAYGROUND_REPORT_TABLE',
  'BMT_LABS_REPORT_TABLE'
];

export { IDENTITY_FEATURE_SPLITS };
